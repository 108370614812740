<template>
  <div>
    <el-button type="primary" @click="openDialog('form')">新建公司</el-button>
    <pagination-table
      ref="pageTable"
      :api="findPage"
      :pageSize="50"
      :apiParams="apiParams"
      height="calc(100vh - 312px)"
    >
      <el-table-column label="编号" prop="id" min-width="160" />
      <el-table-column label="公司名称" prop="payCompanyName" min-width="120" />
      <el-table-column label="操作" min-width="60" align="right">
        <template slot-scope="scope">
          <el-button type="text" @click="handleEditItem(scope.row)">
            编辑
          </el-button>
        </template>
      </el-table-column>
    </pagination-table>

    <el-dialog
      width="30%"
      :title="currentCompanyId ? '编辑公司' : '新建公司'"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" class="dialog-form" ref="form">
        <el-form-item
          label="公司全称"
          prop="payCompanyName"
          label-width="100px"
        >
          <el-input
            v-model.trim="form.payCompanyName"
            placeholder="请输入"
            showWordLimit
            maxlength="100"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="handleDialogConfirm('form')"
          >保 存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PaginationTable from '@/components/PaginationTable.vue';
import payCenterApi from '@/api/paycenter';
import { mapState } from 'vuex';
export default {
  components: {
    PaginationTable,
  },
  name: 'Company',
  data() {
    return {
      loading: false,
      apiParams: {},
      dialogFormVisible: false,
      currentCompanyId: '',
      form: {
        payCompanyName: '',
      },
      rules: {
        payCompanyName: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入公司名称',
          },
        ],
      },
    };
  },

  methods: {
    findPage: payCenterApi.findDefaultPages,
    openDialog(formName) {
      this.form.id && delete this.form.id;
      this.currentCompanyId = '';
      this.form.payCompanyName = '';
      this.$refs[formName]?.clearValidate();
      setTimeout(() => {
        this.dialogFormVisible = true;
      }, 100);
    },
    // 弹框确认
    async handleDialogConfirm(formName) {
      this.$refs[formName]?.validate(valid => {
        if (valid) {
          this.submitData();
        }
      });
    },
    handleSearch() {
      this.$refs['pageTable'].onLoad();
    },
    async submitData() {
      try {
        let res;
        this.loading = true
        if (!this.currentCompanyId) {
          res = await payCenterApi.createDefault({
            ...this.form,
          });
        } else {
          res = await payCenterApi.updateDefault({
            ...this.form,
            id: this.currentCompanyId,
          });
        }
        this.loading = false;
        res?.data
          ? this.$message.success('保存成功')
          : this.$message.error('保存失败');
        this.dialogFormVisible = false;
        this.handleSearch();
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    // 编辑
    async handleEditItem(item) {
      this.form.payCompanyName = item.payCompanyName;
      this.currentCompanyId = item.id;
      this.$refs['form']?.clearValidate();
      setTimeout(() => {
        this.dialogFormVisible = true;
      }, 100);
    },
  },
  computed: {
    ...mapState(['partnerId']),
  },
};
</script>
<style lang="less" scoped>
.dialog-form {
  .el-form-item {
    display: flex;
    /deep/.el-form-item__content {
      width: 80%;
      margin-left: 0 !important;
    }
  }
}
</style>
